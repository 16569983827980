/*
 * @Author: your name
 * @Date: 2021-01-08 21:09:07
 * @LastEditTime: 2022-02-14 15:23:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/main.js
 */
import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './style/index.scss'
import "./assets/iconfont/iconfont.css";
import { get, post } from './utils/request'
import { chatUrl, statShowDebug } from './utils/config'
import {
  getLocalStorageStr, getCurLanguage, goGoodsDetail, goStoreDetail, checkMobile, checkPhone, checkEmailCode, checkPwd, checkSmsCode, checkIdentity,
  checkMemberName, checkImgCode, addCart, diyNavTo, sldCommonTip, checkEmail, getUserInfo, cartDataNum, getLoginCartListData, calculateSubtotal, goGoodsListByCatId, formatChatTime, isShowTime
} from './utils/common';
import { sldStatEvent, initStat } from './utils/stat'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import VueSocketIO from "vue-socket.io";
import coverImage from '@/components/coverImage';
import {VDistpicker} from 'v-distpicker'
import 'dayjs/locale/zh-cn';

ElementPlus.useLang = (app, ref, locale) => { //处理组件中文展示
    const template = (str, option) => {
      if (!str || !option) return str
      return str.replace(/\{(\w+)\}/g, (_, key) => {
        return option[key]
      })
    }
    // 注入全局属性,子组件都能通过inject获取
    app.provide('ElLocaleInjection', {
      lang: ref(locale.name),
      locale: ref(locale),
      t: (...args) => {
        const [path, option] = args
        let value
        const array = path.split('.')
        let current = locale
        for (let i = 0, j = array.length; i < j; i++) {
          const property = array[i]
          value = current[property]
          if (i === j - 1) return template(value, option)
          if (!value) return ''
          current = value
        }
      },
    })
  }
const app = createApp(App);

app.component('coverImage', coverImage)
app.component('v-distpicker', VDistpicker)

//组件引用 end
// 配置全局属性
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$getLocalStorageStr = getLocalStorageStr;
app.config.globalProperties.$getCurLanguage = getCurLanguage;
app.config.globalProperties.$goGoodsDetail = goGoodsDetail;
app.config.globalProperties.$goStoreDetail = goStoreDetail;
app.config.globalProperties.$checkMobile = checkMobile;
app.config.globalProperties.$checkPwd = checkPwd;
app.config.globalProperties.$checkSmsCode = checkSmsCode;
app.config.globalProperties.$checkMemberName = checkMemberName;
app.config.globalProperties.$checkImgCode = checkImgCode;
app.config.globalProperties.$addCart = addCart;
app.config.globalProperties.$diyNavTo = diyNavTo;
app.config.globalProperties.$sldCommonTip = sldCommonTip;
app.config.globalProperties.$checkEmail = checkEmail;
app.config.globalProperties.$getUserInfo = getUserInfo;
app.config.globalProperties.$cartDataNum = cartDataNum;
app.config.globalProperties.$getLoginCartListData = getLoginCartListData;
app.config.globalProperties.$calculateSubtotal = calculateSubtotal;
app.config.globalProperties.$checkEmailCode = checkEmailCode;
app.config.globalProperties.$goGoodsListByCatId = goGoodsListByCatId;
app.config.globalProperties.$formatChatTime = formatChatTime
app.config.globalProperties.$isShowTime = isShowTime
app.config.globalProperties.$sldStatEvent = sldStatEvent
app.config.globalProperties.$checkPhone = checkPhone
app.config.globalProperties.$checkIdentity = checkIdentity
app.use(ElementPlus, { locale })
ElementPlus.useLang(app, ref, locale)
app.use(new VueSocketIO({
  debug: true,
  connection: chatUrl,
}))

app.use(router).use(store).mount('#app')

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

//统计初始化
initStat(statShowDebug, {
  equipmentType: 1,//设备类型，1-pc，2-移动设备，3-其他
  source: 'pc',//终端名称，pc-pc；h5-H5；android-Android；ios-IOS；xcx-微信小程序
  memberId: 0,//会员id默认为0
  ip: '',//移动端ip默认都为空
});



