/*
 * @Author: your name
 * @Date: 2022-02-10 20:41:16
 * @LastEditTime: 2023-02-15 14:10:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /qianyuan_pc/src/utils/config.js
 */
/**
 *  项目的配置文件
 */

 export const apiUrl = 'https://shop.cy-pharm.com/';//线上地址接口请求地址
    //export const apiUrl = 'https://shop.mallcy.cn/';//测试地址接口请求地址
//export const apiUrl = 'http://192.168.102.103:8008/';//本地地址接口请求地址


export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
//  im.cy-pharm.com
// export const chatUrl = 'wss://qianyuanim.slodon.cn';//测试客服地址
export const chatUrl = 'wss://im.cy-pharm.com';//客服地址
export const title = '家庭药师';//浏览器顶部title
export const gdKey = 'c6979f9121ed9d1d6cd46f5fc2ab30e3';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试
// ** copyright *** slodon *** version-v3.4 *** date-2021-10-20 ***主版本v3.4



